import React from 'react';

import { LOCATORS } from 'const';
import { useProjects } from 'store';
import Card from 'components/Card';

import './SectionProjects.scss';

const SectionProjects: React.FC = () => {
  const { projects } = useProjects();

  return (
    <section id={LOCATORS.SECTION_PORJECTS} className='section-projects'>
      {projects.map(project => (
        <Card key={project._id} project={project} />
      ))}
    </section>
  );
};

export default SectionProjects;
