const career = [
  {
    "_id": "1",
    "title": "Senior Software Engineer",
    "company": {
      "name": "Formind",
      "url": null
    },
    "location": "Moscow, Russia",
    "employmentAt": "Dec 2022",
    "terminationAt": null
  },
  {
    "_id": "2",
    "title": "Software Engineer II",
    "company": {
      "name": "GK SAMOLET",
      "url": "https://samolet.ru/"
    },
    "location": "Moscow, Russia",
    "employmentAt": "Apr 2021",
    "terminationAt": "Dec 2022"
  },
  {
    "_id": "3",
    "title": "Software Engineer II",
    "company": {
      "name": "MST Agency",
      "url": "https://mst.agency/"
    },
    "location": "Ulyanovsk, Russia",
    "employmentAt": "Nov 2020",
    "terminationAt": "Apr 2021"
  },
  {
    "_id": "4",
    "title": "Software Engineer I",
    "company": {
      "name": "66bit",
      "url": "https://66bit.dev/"
    },
    "location": "Yekaterinburg, Russia",
    "employmentAt": "Dec 2019",
    "terminationAt": "Nov 2020"
  },
  {
    "_id": "5",
    "title": "Web Developer",
    "company": {
      "name": "SEZ Alabuga",
      "url": "https://alabuga.ru/"
    },
    "location": "Elabuga, Russia",
    "employmentAt": "Dec 2018",
    "terminationAt": "Dec 2019"
  },
  {
    "_id": "6",
    "title": "Web Developer",
    "company": {
      "name": "Freelance",
      "url": null
    },
    "location": "Naberezhnye Chelny, Russia",
    "employmentAt": "Jul 2017",
    "terminationAt": "Dec 2019"
  },
  {
    "_id": "7",
    "title": "3D Artist",
    "company": {
      "name": "Freelance",
      "url": "https://malcore.artstation.com/"
    },
    "location": "Naberezhnye Chelny, Russia",
    "employmentAt": "Oct 2016",
    "terminationAt": "Dec 2018"
  }
];

export default career;
