import { useCallback } from 'react';
import { useRecoilState } from 'recoil';

import client from 'api';

import CareerAtom from '../atom';

export const useCareer = () => {
  const [career, setCareer] = useRecoilState(CareerAtom);

  const onFetch = useCallback(() => {
    return client.career().then(setCareer);
  }, [setCareer]);

  return {
    career,
    setCareer,
    onFetch,
  };
};
