import { atom } from 'recoil';

import type { TCareer } from 'types';

const DEFAULT_STATE: TCareer = [];

export default atom({
  key: 'store-career',
  default: DEFAULT_STATE,
});
