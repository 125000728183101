import React from 'react';

import { LOCATORS } from 'const';
import Background from 'assets/images/background.png';

import './SectionCareer.scss';

const SectionCareer: React.FC = () => {
  return (
    <section id={LOCATORS.SECTION_CAREER} className='section-career'>
      {/* <h2 className='section-career__title'>Career</h2> */}

      <img src={Background} alt='Dan Popov' className='section-contact__background' />
    </section>
  );
};

export default SectionCareer;
