import { useEffect } from 'react';

import { useAbout, useProjects, useCareer } from 'store';

/**
 * A component that securely fetches data from the server
 */
const LazyLoad: React.FC = () => {
  const { onFetch: onFetchAbout } = useAbout();
  const { onFetch: onFetchProjects } = useProjects();
  const { onFetch: onFetchCareer } = useCareer();

  useEffect(() => {
    onFetchAbout().finally(onFetchProjects).finally(onFetchCareer);
  }, [onFetchAbout, onFetchProjects, onFetchCareer]);

  return null;
};

export default LazyLoad;
