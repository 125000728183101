import ProjectImage1 from 'assets/images/project-1.png';
import ProjectImage2 from 'assets/images/project-2.png';
import ProjectImage3 from 'assets/images/project-3.png';

const projects = [
  {
    "_id": "1",
    "image": ProjectImage1,
    "type": "MOBILE_APPLICATION",
    "title": "Project Title 1",
    "description": "The famous Greek salad of crispy lettuce, peppers, olives and our Chicago styled feta cheese, garnished with crunchy garlic, rosemary croutons.",
    "technologies": ["Dart", "Flutter", "Firebase", "AWS"]
  },
  {
    "_id": "2",
    "image": ProjectImage2,
    "type": "WEB_APPLICATION",
    "title": "Project Title 2",
    "description": "The famous Greek salad of crispy lettuce, peppers, olives and our Chicago styled feta cheese, garnished with crunchy garlic, rosemary croutons.",
    "technologies": ["Dart", "Flutter", "Firebase", "AWS"]
  },
  {
    "_id": "3",
    "image": ProjectImage3,
    "type": "MOBILE_APPLICATION",
    "title": "Project Title 3",
    "description": "The famous Greek salad of crispy lettuce, peppers, olives and our Chicago styled feta cheese, garnished with crunchy garlic, rosemary croutons.",
    "technologies": ["Dart", "Flutter", "Firebase", "AWS"]
  },
];

export default projects;
